body.dm-Home .t-Body { color: #FFF; }

body.dm-Home .t-Body div#t_Body_content { margin-top: 0 !important; }

.dm-Home.dm-Page .t-Body-contentInner { margin: 0 auto; }

.dm-Home .t-Body-content { padding-bottom: 0; }

.dm-Home .t-Body-topButton { display: none !important; }

.dm-Home .t-Body { background-color: transparent; }

.dm-Home .dm-Logo { font-weight: 700; }

.dm-Home .dm-Hero { border-width: 0; }

.dm-Home .dm-Hero > h1 { font-size: 6rem; line-height: 1.25; font-weight: 700; margin-top: 20px;}

.dm-Home .dm-Hero > h1 span { font-size: 2.4rem; padding-bottom: 20px; font-weight: 300;}

.dm-Home .dm-Hero > h2 { font-size: 3.0rem; }

.dm-Home .dm-Hero > p {     font-size: 2rem;
    line-height: 1.5;
    text-align: center;
 }

.dm-Home .t-Card:hover { -webkit-transform: none; transform: none; }

.dm-Home .t-Cards--featured .t-Card-wrap { background: rgba(255, 255, 255, 0.2); color: #FFF; border-color: rgba(255, 255, 255, 0.1); border: none; background: none; }

.dm-Home .t-Cards--featured .t-Card-wrap:hover, .dm-Home .t-Cards--featured .t-Card-wrap:focus { background: none; border-color: none; }

.dm-Home .t-Cards--animColorFill .t-Card:hover .t-Card-colorFill:before { content: none; }

.dm-Home .t-Card-title { font-weight: 600; font-size: 20px !important; }

.dm-Home .t-Card-icon .t-Icon, .dm-Home .t-Card-title { color: inherit; }

.dm-Home .t-Cards--featured.t-Cards--hideBody .t-Card .t-Card-titleWrap { margin-top: 0; }

.dm-gt-description { padding-top: 1.2rem; padding-bottom: 1.2rem; }

.dm-Home .t-Card-colorFill, .dm-Home .t-Card-icon {
    background-color: #0378fe !important;
}

.dm-Logo-fos {
    width: 200px;
    height: 200px;
    display: block;
    margin: 0 auto;
    background-color: transparent !important;
}

.dm-Hero { border-bottom: 1px solid rgba(255, 255, 255, 0.25); margin: 1.5rem 0; }

.dm-Hero > h1, .dm-Hero > h2 { text-align: center; margin: 0; }

.dm-Hero > h1 { font-size: 64px; }

.dm-Hero > h1 span { display: block; font-size: 32px; }

.dm-Hero > h2 { font-size: 48px; opacity: .8; }

.dm-Hero > p { font-size: 2rem; line-height: 1.5; }

.dm-Hero > .demo-title-h2 { font-size: 2.8rem; }

.dm-Hero--secondary { margin-bottom: 24px; }

.dm-Hero--secondary > .container { max-width: 80%; margin: 0 auto; }

.dm-Hero-steps { background-color: rgba(0, 0, 0, 0.025); border-radius: 2px; padding: 16px; margin-top: 16px; box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) inset; }

.dm-Hero .t-TabsRegion-items { margin-top: 36px; }

p.dm-Hero-steps--slim { font-size: 14px; text-align: center; margin: 32px 0 0 0; padding: 8px; }

.dm-Hero-steps + .t-TabsRegion-items > .a-Region-carouselControl { margin-bottom: 8px; }

.dm-Hero-screenshot .t-Button { display: block; margin: 0 auto; }

.dm-Hero .t-Alert--wizard { max-width: 100%; }

h2.demo-title-h2 { text-align: left; font-weight: bold; }

h3.demo-title-h3 { margin-top: 2.5rem; font-size: 2rem; }
